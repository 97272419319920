#CtaContainer {
  position: relative;
  top: 98px;
  padding-bottom: 2em;
  height: calc(100vh - 98px);
}

  #CtaContainer > div.row {
    height: 100%;
  }

  #CtaContainer #CtaAnimationContainer {
    position: relative;
    height: 100%;
    display: flex;
  }

  #CtaContainer #CtaAnimationContainer #CtaAboutMeContainer {
      position: relative;
      margin: auto;
      padding: 1em;
      width: 100%;
      z-index: 10;
  }

    #CtaContainer #CtaAnimationContainer #CtaAboutMeContainer #MainGreeting {
      font-family: "Marcellus", serif;
      font-size: 4.5em;
      font-weight: bold;
      color: #fff;
      text-shadow: 1px 1px 2px #000;
    }

      @media (max-width: 991px) {
        #CtaContainer #CtaAnimationContainer #CtaAboutMeContainer #MainGreeting {
          font-size: 2.5em;
        } 
      }

    #CtaContainer #CtaAnimationContainer #CtaAboutMeContainer #MainTagline {
      font-family: "DM Sans", sans-serif;
      color: #eee;
      text-shadow: 1px 1px 1px #000;    
    }

    #CtaContainer #CtaAnimationContainer #CtaAboutMeContainer a.btn {
      margin-top: 4em;
      padding: 0.9em 1.2em;
      /*text-transform: uppercase;*/
      background-color: rgba(255, 255, 255, 0.85);
      box-shadow: 1px 1px 8px 1px #212121b8;
      border-radius: 0;
      transition: all 800ms;
    }

      #CtaContainer #CtaAnimationContainer #CtaAboutMeContainer a.btn:hover {
        background-color: #fff;
      }

  #CtaContainer #CtaAnimationContainer #CtaMainAnimation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #CtaContainer #CtaAnimationContainer #CtaMainAnimationOverlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }