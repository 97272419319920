.spinner-container {
    display: flex;
    background: #616161;
    width: 100%;
    height: 100vh;
    animation: fade-out 2250ms;
}

@keyframes fade-out {
  from {
    opacity: 100;
  }
  to {
    opacity: 0;
  }
}

.spinner {
    text-align: center;
    margin: auto;
    position: relative;
}
.spinner div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  animation: grid-animation 1.2s linear infinite;
}
.spinner div:nth-child(1) {
  top: -32px;
  left: -32px;
  animation-delay: 0s;
}
.spinner div:nth-child(2) {
  top: -32px;
  left: -8px;
  animation-delay: -0.4s;
}
.spinner div:nth-child(3) {
  top: -32px;
  left: 16px;
  animation-delay: -0.8s;
}
.spinner div:nth-child(4) {
  top: -8px;
  left: -32px;
  animation-delay: -0.4s;
}
.spinner div:nth-child(5) {
  top: -8px;
  left: -8px;
  animation-delay: -0.8s;
}
.spinner div:nth-child(6) {
  top: -8px;
  left: 16px;
  animation-delay: -1.2s;
}
.spinner div:nth-child(7) {
  top: 16px;
  left: -32px;
  animation-delay: -0.8s;
}
.spinner div:nth-child(8) {
  top: 16px;
  left: -8px;
  animation-delay: -1.2s;
}
.spinner div:nth-child(9) {
  top: 16px;
  left: 16px;
  animation-delay: -1.6s;
}
@keyframes grid-animation {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}