.body-section::before,
.work-example-container::before {
    display: block;
    content: " ";
    margin-top: -98px;
    height: 98px;
    visibility: hidden;
    pointer-events: none;
}

#MyWorkContainer, 
#MySkills .section-container {
  position: relative;
  background: #fff;
}

.section-heading-container {
  background-color: #dadada;
  background-image: url('https://images.unsplash.com/photo-1483959651481-dc75b89291f1?q=80&w=2470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-attachment: fixed;
  background-size: cover;
  box-shadow: 0 0 2px 1px #F5F5F5;
  position: relative;
  z-index: 1;
}

.section-heading {
  padding: 0.5em;
  background-color: rgba(0,0,0,0.85);
}
  
  .section-heading .section-title {
    font-family: "Marcellus", serif;
    font-size: 4em;
    text-shadow: 1px 1px 2px #000000;
    color: #fff;
  }

  .section-heading .section-description {
    color: #fff;
  }

.section-container {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 2fr 3fr;
  grid-template-columns: 2fr 3fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 4em;
  text-align: left;
  align-items: center;
  padding: 5em 3em !important;
}

.work-example-container:nth-child(even) .section-container {
  grid-template-columns: 3fr 2fr;
  background-color: #eee;
  background-image: url('https://images.unsplash.com/photo-1588345921523-c2dcdb7f1dcd?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
}

  .work-example-container:nth-child(even) .section-container .body-supporting-image {
    order: -1;
  }

.section-container.full-width {
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;  
}

.body-title {
  font-family: "Marcellus", serif;
}

.body-description {
  align-self: baseline;
}

.body-supporting-image img {
  width: 100%;
}


.connector {
  position: absolute;
  border-left: 1px solid #BDBDBD;
  height: 100%;
  top: 0;
  text-align: left;
  padding-left: 0 !important;
  width: 100px !important;
}

.connector-icon {
  position: fixed;
  bottom: 0;
  width: 3em;
  height: 3em;
  background-color: #fff;
  border-radius: 50%;
  margin-bottom: 1em;
  margin-left: -1.5em;
  cursor: pointer;
  transition: all 750ms;
}

  .connector-icon:hover,
  .connector-icon:active,
  .connector-icon:focus {
    width: 3.5em;
    height: 3.5em;
    margin-left: -1.75em;
  }


.software-icon-container {
    margin-bottom: 3em;
}

  .software-icon-container .software-icon {
    display: block;
    position: relative;
    height: 100px;
    width: 100px;
    text-align: center;
    margin: auto;
    background-color: #fff;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 1px 1px 5px #607d8b;
  }

      .software-icon-container .software-icon img {
        height: 100%;
        width: auto;
      }

    .software-icon-container .software-icon-text {
      text-align: center;
    }

@media (max-width: 991px) {
  .section-heading .section-title {
    font-size: 3em;
  } 

  .section-container, 
  .work-example-container:nth-child(even) .section-container {
    grid: none;
    text-align: center;
  } 

  .work-example-container:nth-child(even) .section-container .body-supporting-image {
    order: 1;
  }

  .connector-icon {
    width: 2em;
    height: 2em;
    margin-left: 0.5em;
  }

    .connector-icon:hover {
      width: 2.5em;
      height: 2.5em;
      margin-left: 0.75em;
    }   
}      