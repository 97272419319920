.social-links a.nav-link {
  background: #424242;
  margin-left: 12px;
  border-radius: 1px;
  color: #000 !important;
  /* box-shadow: 1px 1px 2px 0px #e2e2e2; */
  /* border: 1px solid #E0E0E0; */
  width: 46px !important;
}

@media (max-width: 991px) {
  .social-links {
    flex-direction: row !important;
    position: absolute;
    top: 30px;
    width: 100%;
    justify-content: center;
  }  
}